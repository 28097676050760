.card-body .flex-grow-1 {
    text-align: left;
}

.side-bg0 {
    --bs-bg-rgb-color: #005F73;
    background-color: #005F73 !important;
}

.side-bg1 {
    --bs-bg-rgb-color: #0A9396;
    background-color: #0A9396 !important;
}

.side-bg2 {
    --bs-bg-rgb-color: #94D2BD;
    background-color: #94D2BD !important;
}


.side-bg3 {
    --bs-bg-rgb-color: #E9D8A6;
    background-color: #E9D8A6 !important;
}


.side-bg4 {
    --bs-bg-rgb-color: #EE9B00;
    background-color: #EE9B00 !important;
}


.side-bg5 {
    --bs-bg-rgb-color: #CA6702;
    background-color: #CA6702 !important;
}


.side-bg6 {
    --bs-bg-rgb-color: #BB3E03;
    background-color: #BB3E03 !important;
}

.side-bg7 {
    --bs-bg-rgb-color: #AE2012;
    background-color: #AE2012 !important;
}

.side-bg8 {
    --bs-bg-rgb-color: #9B2226;
    background-color: #9B2226 !important;
}

.padding-left-10 {
    padding-left: 10px;
}


.pdf-height {
    height: 100%;
}